<template>
  <div class="box-code" v-loading="loading">
    <div class="navs" style="background-color: #f5c319">
      <el-button
        type="primary"
        @click="$router.push({ name: 'works' })"
        size="small"
      >
        作品中心
      </el-button>
      <el-dropdown class="drop-menu gullters" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-button type="primary" size="small">
            文件操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">新建</el-dropdown-item>
          <el-dropdown-item command="2">
            导入
            <input
              @change="fileChange"
              id="myFile"
              name="myFile"
              value="上传"
              type="file"
              accept=".py"
              style="display: none"
            />
          </el-dropdown-item>
          <el-dropdown-item command="3">导出</el-dropdown-item>
          <el-dropdown-item command="4">另存为</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-input
        style="display: inline-block; width: 200px"
        class="gullters"
        v-model="formInfo.title"
        size="small"
        placeholder="请输入作品名称"
      ></el-input>
      <el-button
        class="gullters"
        style="display: inline-block"
        @click="openWinEditor"
        type="primary"
        size="small"
        >保存</el-button
      >
      <el-button @click="openWinList" type="primary" size="small"
        >Python游戏作品</el-button
      >
      <el-dropdown class="drop-menu gullters" @command="handlePygame">
        <span class="el-dropdown-link">
          <el-button type="primary" size="small">
            Pygame样例
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </span>
        <el-dropdown-menu
          style="max-height: 200px; overflow-y: auto"
          slot="dropdown"
        >
          <el-dropdown-item
            v-for="(item, index) in pygameList"
            :key="index"
            :command="item"
            >{{ index + 1 }}、{{ item }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- <iframe class="iframe-style" ref="iframe" src="pygame/index.html"></iframe> -->
    <iframe
      class="iframe-style"
      ref="pygameiframe"
      :src="iframeWinPath"
      seamless
      allowfullscreen="true"
      id="pygameiframe"
      sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
    ></iframe>
    <!-- 提交编辑 -->
    <WorkEditor
      @saveFile="saveFile"
      :formInfos="formInfo"
      :rules="rules"
      ref="editor"
    />
    <!-- 作品列表 -->
    <WorkList @showItem="showItem" ref="list" />
    <!-- 个人素材库 -->
    <el-dialog
      title="个人素材库"
      :visible.sync="sourseModal"
      width="800px"
      append-to-body
    >
      <SourseList @loadToEdit="loadToEdit" ref="sourse" />
    </el-dialog>

    <!-- 素材重命名 -->
    <el-dialog
      title="素材重命名"
      :visible.sync="renameModal"
      width="40%"
      append-to-body
    >
      <SourseRename
        v-if="renameModal"
        @cancel="renameModal = false"
        @renameFile="renameFile"
        :formInfos="currentRenameInfo"
        ref="name"
      />
    </el-dialog>
    <!-- 图片素材大小设置 -->
    <el-dialog
      title="图片素材大小设置"
      :visible.sync="sizeSetModal"
      width="40%"
      append-to-body
    >
      <SourseSizeSet
        v-if="sizeSetModal"
        @cancel="sizeSetModal = false"
        @sizeSetFile="sizeSetFile"
        :formInfos="currentRenameInfo"
        ref="size"
      />
    </el-dialog>

    <!-- 素材预览 -->
    <el-dialog
      title="素材预览"
      :visible.sync="previewModal"
      width="800px"
      append-to-body
    >
      <SourcePreview
        v-if="previewModal"
        @cancel="previewModal = false"
        :scanInfo="currentRenameInfo"
        ref="preview"
      />
    </el-dialog>

    <el-dialog title="确认移除" :visible.sync="removeModal" width="30%">
      <div style="text-align: center">移除后不可恢复，确认移除吗？</div>
      <div style="text-align: center" slot="footer" class="dialog-footer">
        <el-button @click="removeModal = false">取 消</el-button>
        <el-button type="primary" @click="removeSource">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getWorkInfo,
  editWorkInfo,
  saveProject,
  saveTaskProject,
} from "@/api/works/index";
import {
  listWorksSource,
  delWorksSource,
  addWorksSource,
  editWorksSource,
} from "@/api/works/source";

import { uploadFile, getFileText } from "@/api/works/file";
import WorkEditor from "./components/editor.vue";
import WorkList from "./components/workList.vue";
import SourseList from "./components/sourseList.vue";
import SourseRename from "./components/rename.vue";
import SourseSizeSet from "./components/sizeset.vue";
import SourcePreview from "./components/preview.vue";

export default {
  name: "pygame",
  components: {
    WorkEditor,
    WorkList,
    SourseList,
    SourseRename,
    SourseSizeSet,
    SourcePreview,
  },
  props: {
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sourseModal: false,
      renameModal: false,
      previewModal: false,
      sizeSetModal: false,
      removeModal: false,
      currentRenameInfo: {},
      activeIndex: "1",
      loading: false, //编辑器
      iframeWin: {}, //
      iframeWinPath:
        process.env.VUE_APP_PYGAME_IDE + "/pygame/index.html",
      // iframeWinPath: "pygame/index.html",
      saveAs: false,
      formInfo: {
        title: "", //	是	String	作品名称
        description: "", //		否	String	简介
        cover: "", //		否	String	封面
        label: "", //		否	String	标签 (‘游戏’)
        category: "Pygame", //		是	String	类别(‘图形化编程’,’Python’,’Microbit’)
        source: "创作", //		是	String	来源(‘活动’,’创作’,’课程’)
        content: "", //		否	String	内容
        type: "文件", //		是	String	类型(‘文件’,’代码’）,类型为“文件”时，将文件路径置于sourceCodeFile,类型为“代码”时，将代码内容置于content
        sourceCodeFile: "", //		否	String	源代码文件的路径
        videoFile: "", //		否	String	作品视频简介
      },
      rules: {
        title: [
          { required: true, message: "作品名称不可为空", trigger: "blur" },
        ],
        description: [
          { required: false, message: "作品描述", trigger: "blur" },
        ],
      },
      PythonList: [],
      pygameList: ["snake.py", "spirograph.py", "spaceship.py", "draw.py"],
      pygameLists: [
        "advanced-exmp.py",
        "color_test.py",
        "display_test.py",
        "draw.py",
        "drawing-exmp.py",
        "events-exmp.py",
        "font.py",
        "font_test.py",
        "get_pressed_test.py",
        "image-exmp.py",
        "image_test.py",
        "keyup-example.py",
        "key_example.py",
        "key_test.py",
        "mouse_test.py",
        "primer1.py",
        "primer2.py",
        "primer3.py",
        "primer4.py",
        "primer5.py",
        "pygame_test.py",
        "rect_method_test.py",
        "rect_property_test.py",
        "simple_game.py",
        "snake.py",
        "spaceship.png",
        "spaceship.py",
        "spirograph.py",
        "surface_test.py",
        "tick_clock.py",
        "transform_test.py",
        "transform_test2.py",
        "version_test.py",
      ],
      pygameListCopy: [],
    };
  },
  watch: {
    formInfo: {
      handler(val) {},
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.initPro();
  },
  methods: {
    // 获取作品素材列表
    listWorksSource(id) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      listWorksSource({
        creationId: id,
      }).then((res) => {
        if (res.body && res.body.length > 0) {
          let arr = res.body.map((item) => {
            return {
              ...item,
              materialId: item.id,
            };
          });
          this.pygameListCopy = arr;
          let flag = true;
          let that = this;
          setData();
          function setData() {
            setTimeout(() => {
              if (pygameDom.initSourse && flag) {
                pygameDom.initSourse(arr);
                flag = false;
              } else {
                setData();
              }
            }, 200);
          }
        }
        // console.log(res);
      });
    },
    handleSelect(val) {
      conosle.log(val);
    },
    initPro() {
      this.iframeWin = document.getElementById("pygameiframe").contentWindow;
      window.addEventListener("message", this.handleMessage);

      if (this.$route.query.id) {
        this.$nextTick(() => {
          this.getWorkInfo(this.$route.query.id);
          this.listWorksSource(this.$route.query.id);
        });
      } else {
        if (this.$route.query.source) {
          this.formInfo.source = this.$route.query.source;
        }
        if (this.source) {
          this.formInfo.source = this.source;
        }
      }
    },
    // 重命名素材形成
    renameFile(data) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      let alias = data.firstName + "." + data.lastName;
      let flag = false;
      let arr = pygameDom.pygameConfig.list;
      if (arr && arr.length > 0) {
        arr.forEach((el) => {
          if (el.alias == alias && data.materialId != el.materialId) {
            flag = true;
          }
        });
      }
      if (flag) {
        this.$message.warning("和作品素材名称冲突");
        return;
      }
      this.renameModal = false;
      pygameDom.replaceSource({
        ...data,
        alias,
      });
    },
    //https://help.aliyun.com/document_detail/44688.html
    sizeSetFile(data) {
      this.sizeSetModal = false;
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      pygameDom.replaceSource({
        ...data,
        width: data.width,
        height: data.height,
      });
    },
    removeSource() {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      pygameDom.removeSource(this.currentRenameInfo);
      this.removeModal = false;
    },
    // python列表 项目跳入
    showItem(item) {
      this.getWorkInfo(item.id);
      this.listWorksSource(item.id);
      this.$refs.list.close();
    },
    //加载至编辑区
    loadToEdit(data) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      let alias = data.name;
      let flag = false;
      let arr = pygameDom.pygameConfig.list;
      // console.log(arr, data);
      if (arr && arr.length > 0) {
        arr.forEach((el) => {
          if (el.alias == alias && data.materialId != el.materialId) {
            flag = true;
          }
        });
      }
      if (flag) {
        this.$message.warning("和作品素材名称冲突");
        return;
      }
      let img = new Image();
      img.src = data.url;
      img.onload = () => {
        pygameDom.addSourse({
          name: data.name,
          alias: data.name,
          materialId: data.id,
          width: img.width,
          height: img.height,
          url:data.url,
          type:data.type
        });
      };
    },
    // 初次获取详情信息
    getWorkInfo(id) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      getWorkInfo({
        id,
      })
        .then((res) => {
          if(res.body){
            this.formInfo = res.body;
          }else {
            this.loading = false;
            this.$message.warning('未找到关联模板，请核对相关信息后重试！');
          }
          if (res.body.sourceCodeFile) {
            // 获取文件代码
            getFileText(res.body.sourceCodeFile).then((res) => {
              if (pygameDom.importFile) {
                pygameDom.importFile(res);
                this.loading = false;
                return;
              }
              if (pygameDom.attachEvent) {
                pygameDom.attachEvent("onload", () => {
                  pygameDom.importFile(res);
                  this.loading = false;
                });
              } else {
                pygameDom.onload = () => {
                  pygameDom.importFile(res);
                  this.loading = false;
                };
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openWinEditor() {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      if (!this.formInfo.title) {
        this.$message.warning("作品名称不可为空！");
        return;
      }
      const value = pygameDom.exportFile();
      if (!value) {
        this.$message.warning("代码块不可为空！");
        return;
      }
      this.$refs.editor.open();
    },
    openWinList() {
      this.$refs.list.open();
    },

    async sendMessage(data) {
      // vue向iframe内部传数据
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      pygameDom.postMessage(data, "*");
    },
    //监听父级事件
    handleMessage(msg) {
      switch (msg.data.action) {
        case "save": //先上传文件后提交作品信息
          const file = {
            file: "",
          };
          break;
        case "saveLocal":
          //保存本地 下载
          break;
        case "modal":
          this.sourseModal = true;
          break;
        case "rename":
          this.currentRenameInfo = msg.data.data;
          this.renameModal = true;
          break;
        case "sizeset":
          this.currentRenameInfo = msg.data.data;
          this.sizeSetModal = true;
          break;
        case "copy":
          this.currentRenameInfo = msg.data.data;
          this.$message.success("复制成功");
          break;
        case "preview":
          this.currentRenameInfo = msg.data.data;
          this.previewModal = true;
          break;
        case "remove":
          this.currentRenameInfo = msg.data.data;
          this.removeModal = true;
          break;
      }
    },
    /**
     * 监听常用游戏改变
     */
    handlePygame(path) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      fetch("/pygame/test/" + path)
        .then((res) => {
          return res.text();
        })
        .then((text) => {
          pygameDom.importFile(text);
        });
    },
    //监听文件操作
    handleCommand(event) {
      switch (event) {
        case "1":
          this.newFile();
          break;
        case "2":
          this.importFile();
          break;
        case "3":
          this.exportFile();
          break;
        case "4":
          this.saveAsCloud();
          break;
      }
    },
    // 保存代码
    saveFile(form) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      this.formInfo = {
        ...this.formInfo,
        title: form.title,
        description: form.description,
        // source: form.source,
        sourceList: pygameDom.pygameConfig.list,
      };

      const value = pygameDom.exportFile();
      const content = value.toString();
      var file = new File([content], "json");
      let params = new FormData();
      params.append("file", file);
      uploadFile(params)
        .then((res) => {
          // 上传成功后提交作品信息
          this.loading = false;
          let props = {
            ...this.formInfo,
            sourceCodeFile: res.body.fileUrl,
          };
          // console.log(props);
          if (props.id) {
            this.editWorkInfo(props);
          } else {
            if (props.source == "创作") {
              this.saveProject(props);
            } else {
              this.saveTaskProject(props);
            }
          }
          // props.id ? this.editWorkInfo(props) : this.saveProject(props);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    editWorkInfo(props) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      editWorkInfo({
        id: props.id,
        title: props.title,
        description: props.description,
        category: "Pygame",
        source: props.source,
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("提交成功");
          this.$refs.editor.close();
          // this.getWorkInfo(props.id);
          let arr = pygameDom.pygameConfig.list;
          if (arr && arr.length > 0) {
            arr.forEach((i) => {
              let addOrEdit = this.pygameListCopy.find(
                (a) => a.materialId == i.materialId
              );
              if (i.creationId) {
                editWorksSource({
                  creationId: props.id,
                  materialId: i.materialId,
                  width: i.width,
                  height: i.height,
                  alias: i.alias,
                }).then((res) => {
                  // console.log("1");
                });
              } else {
                addWorksSource({
                  creationId: props.id,
                  materialId: i.materialId,
                  width: i.width,
                  height: i.height,
                  alias: i.alias,
                }).then((res) => {
                  // console.log("1");
                });
              }
            });
            this.pygameListCopy.forEach((i) => {
              let deleteFlag = arr.find((a) => a.materialId == i.materialId);
              if (!deleteFlag) {
                delWorksSource({
                  materialId: i.materialId,
                  creationId: i.creationId,
                }).then((res) => {
                  // console.log(res);
                });
              }
            });
          } else if (this.pygameListCopy.length > 0) {
            this.pygameListCopy.forEach((i) => {
              delWorksSource({
                materialId: i.materialId,
                creationId: i.creationId,
              }).then((res) => {
                // console.log(res);
              });
            });
          }
          // this.$refs.list.getWorksList();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    saveProject(props) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      saveProject({
        title: props.title,
        description: props.description,
        category: "Pygame",
        source: props.source,
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("提交成功");
          this.$refs.editor.close();
          if (res.body.id) {
            let arr = pygameDom.pygameConfig.list;
            if (arr && arr.length > 0) {
              arr.forEach((i) => {
                addWorksSource({
                  creationId: res.body.id,
                  materialId: i.materialId,
                  width: i.width,
                  height: i.height,
                  alias: i.alias,
                }).then((res) => {
                  // console.log(res);
                });
              });
            }
            this.getWorkInfo(res.body.id);
          }
          this.$refs.list.getWorksList();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    saveTaskProject(props) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      saveTaskProject({
        title: props.title,
        description: props.description,
        category: "Pygame",
        source: props.source,
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("提交成功");
          this.$refs.editor.close();
          if (res.body.id) {
            this.getWorkInfo(res.body.id);
            let arr = pygameDom.pygameConfig.list;
            if (arr && arr.length > 0) {
              arr.forEach((i) => {
                addWorksSource({
                  creationId: res.body.id,
                  materialId: i.materialId,
                  width: i.width,
                  height: i.height,
                  alias: i.alias,
                }).then((res) => {
                  // console.log(res);
                });
              });
            }
          }
          this.$refs.list.getWorksList();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    //新建作品
    newFile() {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      pygameDom.newFile();
      this.formInfo.title = null;
      this.formInfo.id = null;
      this.formInfo.description = null;
    },
    //导入作品
    importFile() {
      this.formInfo.description = null;
      this.formInfo.id = null;
      document.getElementById("myFile").click();
    },
    fileChange(even) {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      const file = even.target.files[0];
      this.formInfo.title = file.name.split(".")[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        pygameDom.importFile(reader.result);
      };
    },
    //下载作品
    exportFile() {
      let pygameDom = document.getElementById("pygameiframe").contentWindow;
      const value = pygameDom.exportFile();
      let curProjectName = this.formInfo.title || "works-python";
      const FileSaver = require("file-saver");
      const content = value.toString();
      const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, curProjectName + ".py");
    },
    //另存为
    saveAsCloud() {
      //保存逻辑去掉ID
      this.formInfo.id = null;
      this.openWinEditor();
      // this.saveAs = true;
    },
    // 封面
  },
};
</script>
<style>
.box-code {
  height: calc(100vh);
}
.iframe-style {
  width: 100%;
  height: calc(100vh - 44px);
}
.iframe-style::-webkit-scrollbar {
  display: none;
}

.nav {
  height: 40px;
  padding-top: 4px;
  padding-left: 10px;
  /* background-color: #121212; */
  /* background-image: linear-gradient(to right, #ffe02a, #f5c319, #e6a23c); */
}
.nav::-webkit-scrollbar {
  display: none;
}
.navs {
  height: 40px;
  /* padding-top: 4px; */
  padding-left: 40px;
  background-color: black;
}
.code.box {
  padding: 0;
  margin: 0;
}
.code.box-::-webkit-scrollbar {
  display: none;
}
html,
body {
  padding: 0 !important;
  margin: 0 !important;
}
.drop-menu {
  line-height: 32px;
  display: inline-block;
}
.gullters {
  margin-left: 10px;
}
.python-list {
  float: right;
  margin-right: 20px;
}
html,
body {
  padding: 0;
  margin: 0;
  height: calc(100vh);
  overflow: hidden;
}
</style>