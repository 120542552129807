var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "box-code",
    },
    [
      _c(
        "div",
        { staticClass: "navs", staticStyle: { "background-color": "#f5c319" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "works" })
                },
              },
            },
            [_vm._v(" 作品中心 ")]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "drop-menu gullters",
              on: { command: _vm.handleCommand },
            },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [
                      _vm._v(" 文件操作 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "1" } }, [
                    _vm._v("新建"),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "2" } }, [
                    _vm._v(" 导入 "),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: {
                        id: "myFile",
                        name: "myFile",
                        value: "上传",
                        type: "file",
                        accept: ".py",
                      },
                      on: { change: _vm.fileChange },
                    }),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "3" } }, [
                    _vm._v("导出"),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "4" } }, [
                    _vm._v("另存为"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "gullters",
            staticStyle: { display: "inline-block", width: "200px" },
            attrs: { size: "small", placeholder: "请输入作品名称" },
            model: {
              value: _vm.formInfo.title,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "title", $$v)
              },
              expression: "formInfo.title",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "gullters",
              staticStyle: { display: "inline-block" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.openWinEditor },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.openWinList },
            },
            [_vm._v("Python游戏作品")]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "drop-menu gullters",
              on: { command: _vm.handlePygame },
            },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [
                      _vm._v(" Pygame样例 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticStyle: { "max-height": "200px", "overflow-y": "auto" },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.pygameList, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    { key: index, attrs: { command: item } },
                    [_vm._v(_vm._s(index + 1) + "、" + _vm._s(item))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("iframe", {
        ref: "pygameiframe",
        staticClass: "iframe-style",
        attrs: {
          src: _vm.iframeWinPath,
          seamless: "",
          allowfullscreen: "true",
          id: "pygameiframe",
          sandbox:
            "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
        },
      }),
      _c("WorkEditor", {
        ref: "editor",
        attrs: { formInfos: _vm.formInfo, rules: _vm.rules },
        on: { saveFile: _vm.saveFile },
      }),
      _c("WorkList", { ref: "list", on: { showItem: _vm.showItem } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "个人素材库",
            visible: _vm.sourseModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sourseModal = $event
            },
          },
        },
        [
          _c("SourseList", {
            ref: "sourse",
            on: { loadToEdit: _vm.loadToEdit },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材重命名",
            visible: _vm.renameModal,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.renameModal = $event
            },
          },
        },
        [
          _vm.renameModal
            ? _c("SourseRename", {
                ref: "name",
                attrs: { formInfos: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.renameModal = false
                  },
                  renameFile: _vm.renameFile,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片素材大小设置",
            visible: _vm.sizeSetModal,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sizeSetModal = $event
            },
          },
        },
        [
          _vm.sizeSetModal
            ? _c("SourseSizeSet", {
                ref: "size",
                attrs: { formInfos: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.sizeSetModal = false
                  },
                  sizeSetFile: _vm.sizeSetFile,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材预览",
            visible: _vm.previewModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _vm.previewModal
            ? _c("SourcePreview", {
                ref: "preview",
                attrs: { scanInfo: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.previewModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "确认移除", visible: _vm.removeModal, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.removeModal = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("移除后不可恢复，确认移除吗？"),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.removeModal = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.removeSource } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }